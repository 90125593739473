<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>{{ $t('configuracaoAnaliseCredito') }}</h3>
            </v-col>
            <v-col 
                cols="2" 
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'configAnaliseCreditoForm', params: { id: 0 } }"
                        >
                            {{ $t('novo') }} {{ $t('configuracao') }}
                        </v-btn>
                    </template>
                    <span>{{ $t('novo') }} {{ $t('configuracao') }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        :label="$t('pesquisaRapida')"
                        append-outer-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :no-data-text="$t('nenhumRegistroEncontrado')"
                    :footer-props="{
                        'items-per-page-text':$t('registrosPorPagina'),
                        'items-per-page-all-text':$t('todos'),
                        pageText: '{0}-{1} de {2}'
                    }"
                >
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'configAnaliseCreditoForm'"
                            :showButtons="{
                                edit: true,
                            }" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";

    export default ({

        components: {
            ActionList: ActionList
        },

        mixins: [Helpers],

        data: vue => ({

            loading: false,

            statusId: 0,

            filter: {
                fastSearch: ''
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: vue.$t('valorInicial'), value: "valorInicialFormatted", sortable: true, size: "20%" },
                { text: vue.$t('valorFinal'), value: "valorFinalFormatted", sortable: true, size: "20%" },
                { text: vue.$t('descricao'), value: "descricao", sortable: true, size: "20%" },
                { text: vue.$t('sugereAnalise'), value: "bloqueiaDescricao", sortable: true, size: "20%" },
                { text: vue.$t('acoes'), value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listConfigAnaliseCredito: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listConfigAnaliseCredito.filter((configAnaliseCreditoFilter) => {
                    
                    const filter = this.filter.fastSearch.toLowerCase();

                    const valorInicialFormatted = configAnaliseCreditoFilter.valorInicialFormatted.toLowerCase().match(filter)
                    const valorFinalFormatted = configAnaliseCreditoFilter.valorFinalFormatted.toLowerCase().match(filter)
                    const descricao = configAnaliseCreditoFilter.descricao.toLowerCase().match(filter)
                    const bloqueiaDescricao = configAnaliseCreditoFilter.bloqueiaDescricao.toLowerCase().match(filter)
                    const id = configAnaliseCreditoFilter.id.toString().match(filter);

                    if(valorInicialFormatted != null) { return valorInicialFormatted; } 
                    else if(valorFinalFormatted != null) { return valorFinalFormatted; }
                    else if(descricao != null) { return descricao; }
                    else if(bloqueiaDescricao != null) { return bloqueiaDescricao; } 
                    else { return id; }
                });
            }
        },

        methods: {
            
            async getRegisters() {

                this.listConfigAnaliseCredito = await this.$store.dispatch("analiseCreditoModule/List");
            },
        },

        async created() {
            this.showLoading();

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>
